const Api = async (formData, setResponse, setIsLoading, setErrorMessage) => {
  
  setIsLoading(true);

  try {
    const response = await fetch("http://152.70.71.205:4490", {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      setResponse(data);
      setErrorMessage(null);
    } else {
      setErrorMessage(
        "⚠️ That image has a problem. Try again with a different one please... 🙏"
      );
    }
  } catch (error) {
    setErrorMessage("Someone turned off Wi-Fi. 📶 Please help! 😩");
  } finally {
    setIsLoading(false);
  }
};

export default Api;
