import React, { useState, useEffect } from "react";
import "./App.css";
import Api from "./services/Api";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Cookies from "js-cookie";
import ImageWithSquare from "./components/ImageWithSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

function App() {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 1);

      Cookies.set("access_token", codeResponse.access_token, {
        expires: expirationDate,
      });
      setErrorMessage(null);
    },
    onError: () => setErrorMessage("Authentication Error"),
  });

  useEffect(() => {
    const savedAccessToken = Cookies.get("access_token");

    if (savedAccessToken) {
      setUser({ access_token: savedAccessToken });
    }
  }, []);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          setErrorMessage(null);
        })
        .catch(() => setErrorMessage("Google Server not connected!"));
    }
  }, [user]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      setResponse(null);

      const reader = new FileReader();

      reader.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
          setUploadProgress(progress);
        }
      };

      reader.onload = () => {
        setImageURL(reader.result);
        setErrorMessage(null);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    await Api(formData, setResponse, setIsLoading, setErrorMessage);
  };

  const displayMessage = () => {
    if (
      !response ||
      response.data[0] === undefined ||
      response.success !== true
    ) {
      return "Oh...! I tried a lot, but sorry... 😥 Try again with a different image please! 🙏";
    } else {
      if (response && response.data[0].name === "wolf_snake") {
        return (
          "✅ I'm " +
          Math.round(parseFloat(response.data[0].confidence * 100)) +
          "% sure, It should be a Wolf Snake. It is an innocent non-venamous snake. 🥹 But don't pet it! 🥴 Just leave that one alone."
        );
      } else if (response && response.data[0].name === "common_krait") {
        return (
          "✅ I'm " +
          Math.round(parseFloat(response.data[0].confidence * 100)) +
          "% sure, It should be Common Krait! It is a venamous snake! 🚨 Keep your disance, 😯 but please don't hurt that little fella. 😟"
        );
      }
    }
  };

  return (
    <div>
      <NavBar />
      <div className="container">
        <div className="mx-auto custom-margin">
          <h1 className="display-4">Hello... 👋</h1>
          <span className="display-6">I'm your</span>
          <span className="display-4"> Snake Buddy! 🐍</span>
          <p></p>
          {!imageURL && (
            <div className="alert alert-info">
              <ul>
                <li>
                  I can differentiate <strong>Wolf Snakes</strong> and{" "}
                  <strong>Common Kraits</strong> from their photographs. 📸
                </li>
                <li>
                  Simply upload it if you have a picture of one of them. ⬆️
                </li>
                <li>I'm going to make an effort to recognize the snake. 😎</li>
                <li>
                  Just sign in with <strong>Google</strong> to continue.
                </li>
              </ul>
            </div>
          )}
        </div>

        {profile ? (
          <div>
            {!imageURL && (
              <div className="alert alert-success">
                <h5>Hi {profile.given_name}! 🤗</h5>
                <div>
                  Your email <strong>({profile.email})</strong> has been used to
                  verify you.
                </div>
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <input
                className="form-control my-4"
                type="file"
                id="formFile"
                accept=".jpg, .jpeg"
                onChange={handleFileChange}
              ></input>
              {100 > uploadProgress && uploadProgress > 0 && (
                <div className="mt-2 text-center">
                  <h6>Uploading Image:</h6>
                  <progress
                    className="progress mx-auto mb-2"
                    value={uploadProgress}
                    max="100"
                  ></progress>
                </div>
              )}
              {imageURL && (
                <div className="mt-4">
                  <h6>Preview:</h6>
                  {imageURL && !response && (
                    <img
                      className="img-fluid rounded mx-auto d-block uploaded-image mb-4"
                      src={imageURL}
                      alt="Uploaded"
                    />
                  )}
                  {imageURL && response && response.data[0] && (
                    <ImageWithSquare
                      imageURL={imageURL}
                      squareCoordinates={response.data[0].box}
                    />
                  )}
                </div>
              )}

              <div className="d-grid">
                <button
                  disabled={
                    response !== null ||
                    imageURL === null ||
                    isLoading === true ||
                    errorMessage !== null
                  }
                  type="submit"
                  className="btn btn-lg mx-auto btn-primary mb-2"
                >
                  <FontAwesomeIcon icon={faCloudArrowUp} /> Upload
                </button>
                {errorMessage && (
                  <div className="alert alert-danger alert-dismissible">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="alert"
                    ></button>
                    <strong>Error!</strong> {errorMessage}
                  </div>
                )}
              </div>
            </form>
          </div>
        ) : !Cookies.get("access_token") && errorMessage === null ? (
          <div className="d-grid">
            <button
              className="mx-auto btn btn-outline-primary btn-lg"
              onClick={() => login()}
            >
              <FontAwesomeIcon icon={faGoogle} size="xl" /> Sign in with Google
            </button>
          </div>
        ) : (
          <div className="mt-2 text-center">
            <div className="mx-auto spinner-border text-primary"></div>
          </div>
        )}

        {isLoading && (
          <div className="mt-2 text-center">
            <div className="alert alert-info">
              <strong> ⏳️ Wait!</strong> Just give me a moment, I need to take
              a closer look... 👀
            </div>
            <div className="mx-auto spinner-border text-primary"></div>
          </div>
        )}
        {response && (
          <div className="mt-2">
            <blockquote className="blockquote">
              <p>{displayMessage()}</p>
              <footer className="blockquote-footer">Snake Buddy</footer>
            </blockquote>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default App;
