import React, { useEffect } from "react";

function ImageWithSquare({ imageURL, squareCoordinates }) {
  useEffect(() => {
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");

    // Load the image
    const img = new Image();
    img.src = imageURL;
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Draw the square using coordinates from the response
      ctx.strokeStyle = "red"; // Set the square color
      ctx.lineWidth = 10; // Set the square border width
      ctx.strokeRect(
        squareCoordinates.x1,
        squareCoordinates.y1,
        squareCoordinates.x2 - squareCoordinates.x1,
        squareCoordinates.y2 - squareCoordinates.y1
      );
    };
  }, [imageURL, squareCoordinates]);

  return <canvas id="canvas" className="img-fluid rounded mx-auto d-block uploaded-image mb-4" />;
}

export default ImageWithSquare;
